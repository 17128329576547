@font-face {
  font-family: "Roboto Thin";
  src: url("./assets/fonts/Roboto-Thin.ttf");
}
@font-face {
  font-family: "Roboto Light";
  src: url("./assets/fonts/Roboto-Light.ttf");
}
@font-face {
  font-family: "Cormorand Garamont Medium";
  src: url("./assets/fonts/CormorantGaramond-Medium.ttf");
}
@font-face {
  font-family: "Cormorand Garamont Regular";
  src: url("./assets/fonts/CormorantGaramond-Regular.ttf");
}
@font-face {
  font-family: "Cormorand Garamont Light";
  src: url("./assets/fonts/CormorantGaramond-Light.ttf");
}

@font-face {
  font-family: "Cormorand Garamont Light Italic";
  src: url("./assets/fonts/CormorantGaramond-LightItalic.ttf");
}

@font-face {
  font-family: "Cormorand Garamont Semi Bold";
  src: url("./assets/fonts/CormorantGaramond-SemiBold.ttf");
}

@font-face {
  font-family: "Cormorand Garamont Bold";
  src: url("./assets/fonts/CormorantGaramond-Bold.ttf");
}




body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  cursor: url('./assets/images/glowing-cursor-2.png');
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}